/*
 * @Author: your name
 * @Date: 2022-04-13 16:39:01
 * @LastEditTime: 2022-04-13 16:53:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /惠旅云底座/huilvyun_kaifangzhePlatform/src/lib/apply-menu copy.js
 */
// 应用功能注册

import { Get, Post } from "@lib/request";
import { Base } from "./config";

/**
 * 删除应用的菜单
 */
export const delMenuActions = (data) =>
  Post(Base + "/application/delMenuActions", data);

/**
 * 保存菜单功能
 */
export const saveMenuAction = (data) =>
  Post(Base + "/application/saveMenuAction", data);

  /**
 * 新增菜单功能
 */
export const addMenuAction = (data) =>
Post(Base + "/application/addMenuAction", data);


/**
 * 获取菜单功能
 */
export const getMenuAction = (data) =>
  Get(Base + "/application/getMenuAction", data);
