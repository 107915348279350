import { saveMenuAction, addMenuAction } from "@lib/apply-use";
import TextEditer from "@component/TextEditer/index.vue";
export default {
  components: {
    [TextEditer.name]: TextEditer,
  },
  data() {
    return {
      ruleForm: {
        id: '',
        select: "",
        fullName: "",
        menuId: "",
        name: "",
        type: "",
        buttonId: "",
        showType: "",
        displaySeq: "",
        decoration: "",
        url: "",
        pid:''
      },
      disabled: false,
      rules: {
        select: [
          {
            required: true,
            message: "请选择按钮类型",
            trigger: ["change", "blur"],
          },
        ],
        name: [
          { required: true, message: "请填写功能名称", trigger: "change" },
        ],
        id: [
          {
            required: true,
            message: "请填写功能编码",
            trigger: ["change", "blur"],
          },
        ],
        type: [{ required: true, message: "请填写类型", trigger: "change" }],
        buttonId: [
          { required: true, message: "请填写按钮ID", trigger: "change" },
        ],
        showType: [
          { required: true, message: "请选择交互方式", trigger: "change" },
        ],
        // decoration: [
        //   { required: true, message: "请填写提示信息", trigger: "blur" },
        // ],
        url: [{ required: true, message: "请填写功能URL", trigger: "blur" }],
      },
      editOrSee: "",
    };
  },
  async created() {
    this.initDictData("SHOW_TYPE_DICT");
    await this.initDictData("ACTION_TYPE_DICT");
    this.initFrom();
    this.index = 0;
  },
  watch: {
    "ruleForm.select"(n) {
      console.log(n)
      this.actionTypeDictFn(n);
    },
  },
  methods: {
    //返回当前选中ACTION_TYPE_DICT
    actionTypeDictFn(type) {
      const checked = this.dict["ACTION_TYPE_DICT"].filter(
        (item) => type === item.code
      );
      console.log(this.editOrSee)
      // 第一次进来不做任何操作
      if (this.editOrSee && !this.index) {
        this.index = 1;
        return;
      }
      if (checked.length) {
        const { name, code, pcode } = checked[0];
        console.log(name, code, pcode)
        this.ruleForm = {
          ...this.ruleForm,
          name,
          type: code,
          buttonId: pcode,
        };
      }
    },
    //   确认表单信息
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = this.ruleForm;
          delete data["select"];
          let res;
          if (this.editOrSee) {
            res = await saveMenuAction({
              ...data,
              menuId: this.$route.query.menuId,
            });
          } else {
            res = await addMenuAction({
              ...data,
              menuId: this.$route.query.menuId,
            })
          }
          const { status, result } = res;
          if (status === "success") {
            this.$message.success(`${this.editOrSee ? "修改" : "新增"}成功`);
            this.$router.back();
          } else if (status === 'error') {
            this.$message.warning(result.error_msg);
          }
        } else {
          return false;
        }
      });
    },
    initFrom() {
      //初始化from
      const { editOrSee, info } = this.$route.params;
      this.editOrSee = editOrSee;
      if (this.editOrSee == 0) {
        this.disabled = true;
      }
      if (!info) return;
      const from = JSON.parse(info);
      let select = "O";
      for (
        let index = 0;
        index < this.dict["ACTION_TYPE_DICT"].length;
        index++
      ) {
        const m = this.dict["ACTION_TYPE_DICT"][index];
        if (from.type === m.code) {
          select = m.code;
          continue;
        }
      }

      if (info) {
        this.ruleForm = {
          ...this.ruleForm,
          ...from,
          menuId: this.$route.query.menuName,
          select,
        };
      }
    },
  },
  mounted() {
    const applyUse = sessionStorage.getItem("applyUse");
    const { fullName } = JSON.parse(applyUse);
    this.ruleForm.fullName = fullName;
    this.ruleForm.menuId = this.$route.query.menuName;
  },
};
